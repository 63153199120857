<template>
  <v-dialog v-model="show" max-width="800px" scrollable>
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          {{ title }}
          <span v-if="obituaryId" class="body-2">
            <i>#{{ obituary.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn icon small @click="show = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col md="8">
              <validation-provider v-slot="{ errors }" vid="name">
                <v-text-field
                  v-model="obituary.name"
                  hide-details="auto"
                  label="Nome"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="genre">
                <v-radio-group
                  v-model="obituary.genre"
                  hide-details="auto"
                  class="mt-0"
                  :error-messages="errors"
                >
                  <v-radio label="Masculino" value="M"></v-radio>
                  <v-radio label="Feminino" value="F"></v-radio>
                </v-radio-group>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <validation-provider v-slot="{ errors }" vid="birthDate">
                <date-field
                  v-model="obituary.birthDate"
                  label="Data de nascimento"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="deathDate">
                <date-field
                  v-model="obituary.deathDate"
                  label="Data de falecimento"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3">
              <validation-provider v-slot="{ errors }" vid="funeralDate">
                <date-field
                  v-model="obituary.funeralDate"
                  label="Data do velório"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="3">
              <validation-provider v-slot="{ errors }" vid="funeralTime">
                <v-text-field
                  v-model="obituary.funeralTime"
                  hide-details="auto"
                  v-mask="'##:##'"
                  placeholder="15:00"
                  label="Hora do velório"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="3">
              <validation-provider v-slot="{ errors }" vid="burialDate">
                <date-field
                  v-model="obituary.burialDate"
                  label="Data do sepultamento"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="3">
              <validation-provider v-slot="{ errors }" vid="burialTime">
                <v-text-field
                  v-model="obituary.burialTime"
                  hide-details="auto"
                  v-mask="'##:##'"
                  placeholder="15:00"
                  label="Hora do sepultamento"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="funeralAddress">
                <v-text-field
                  v-model="obituary.funeralAddress"
                  hide-details="auto"
                  label="Local do velório"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="burialAddress">
                <v-text-field
                  v-model="obituary.burialAddress"
                  hide-details="auto"
                  label="Local do sepultamento"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon v-text="'mdi-content-save'" left />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import obituaryApi from '@/api/obituary'
import dayjs from 'dayjs'

export default {
  components: {
    DateField: () => import('@/components/general/inputs/Date'),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    obituaryId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      loadingSave: false,
      obituary: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    title() {
      return this.obituaryId ? 'Editar' : 'Adicionar'
    },
  },

  watch: {
    show(val) {
      if (!val) return
      this.load()
    },
  },

  methods: {
    async load() {
      try {
        this.obituary = {}
        if (!this.obituaryId) {
          return
        }

        this.loading = true
        const response = await obituaryApi.get(this.obituaryId)

        const obituary = { ...response.data.obituary }

        if (obituary.funeralDate) {
          const date = dayjs(obituary.funeralDate)
          obituary.funeralDate = date.format('YYYY-MM-DD')
          obituary.funeralTime = date.format('HH:mm')
        }

        if (obituary.burialDate) {
          const date = dayjs(obituary.burialDate)
          obituary.burialDate = date.format('YYYY-MM-DD')
          obituary.burialTime = date.format('HH:mm')
        }

        this.obituary = obituary
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    async save() {
      try {
        this.loadingSave = true
        this.$refs.observer.reset()

        if (this.obituaryId) {
          await obituaryApi.update(this.obituaryId, this.obituary)
        } else {
          await obituaryApi.insert(this.obituary)
        }

        this.show = false
        this.$snackbar.show({
          color: 'success',
          message: this.obituaryId
            ? this.$messages._('update_success')
            : this.$messages._('new_success'),
        })
        this.$emit('save')
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingSave = false
      }
    },
  },
}
</script>
